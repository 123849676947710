import * as Sentry from '@sentry/browser'

const config = JSON.parse(document.getElementById('sentry-config').textContent)

if (config['dsn'] !== null) {
  let full_config = {
    ...config,
    ignoreErrors: [
      /NetworkError/i,
    ],
  }
  Sentry.init(full_config)
}
